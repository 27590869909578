import { Injectable } from '@angular/core';

import {Md5} from 'ts-md5/dist/md5'; 
 
@Injectable({
  providedIn: 'root'
})

export class AlgelService {
  baseURL="https://php.otomatiksera.com/php/api.php"
  md5 = new Md5();
  constructor(    ) { }
  public setHeaders(params,sorgu) {      
    const accessToken = "11111111111111111111111111";
    const reqData = {
       
    };
    if(params) {
        let reqParams = {};        
        Object.keys(params).map(k =>{


          if(k=="sifre"){
            var tr =params.kadi.toLowerCase( );
            var t=this.md5 .start().appendStr(tr).end();
   

            var sifre= this.md5.start().appendStr(t+'.'+params.sifre).end() ;
            
            params[k]=sifre;
       
          }
            reqParams[k] = params[k];
        });
        var s="s";
        reqParams[s] = sorgu;
        reqData['params'] = reqParams;
    }

 
    return reqData;
}

 
}
