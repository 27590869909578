import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {  AlgelService  } from '../algel.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
	user 		  : any;
	userData   : any;
   isLoggedIn = false;
;
   constructor( private http:HttpClient ,
               private router : Router,
            private algel:AlgelService,
               private toastr: ToastrService) { 
  
   }

  public girisYap(t){

      if(t.hata){

         this.toastr.error(t.mesaj);
      
      }else{
         this.setLocalUserProfile(t.sonuc,t.seralar);
         this.toastr.success(t.mesaj);
         this.router.navigate(['/anasayfa']);
      }
    }

    
  public girisYap2(t){

   if(t.hata){

     // this.toastr.error(t.mesaj);
   
   }else{
      this.setLocalUserProfile(t.sonuc,t.seralar);
      //this.toastr.success(t.mesaj);
    //  this.router.navigate(['/anasayfa']);
   }
 }
   /*
    *  getLocalStorageUser function is used to get local user profile data.
    */
   getLocalStorageUser(){
      this.userData = JSON.parse(localStorage.getItem("uye"));
      if(this.userData) {
         this.isLoggedIn = true;
         return true;
      } else {
         this.isLoggedIn = false;
         return false;
      }    
   }
   getLocalStorageSeralar(){
    var   a = JSON.parse(localStorage.getItem("seralar"));
 
      if(a ) {
        
         return a;
      } else {
         
         return false;
      }    
   }
  	/*
    * signupUserProfile method save email and password into firabse &
    * signupUserProfile method save the user sign in data into local storage. 
    */
   signupUserProfile(value) {
/*

    	this.firebaseAuth
   	.auth
      .createUserWithEmailAndPassword(value.email, value.password)
      .then(value => {
        this.toastr.success('Account Created!');
        this.setLocalUserProfile(value);
        this.router.navigate(['/']);
      })
      .catch(err => {
         this.toastr.error(err.message);
      });    */
   }

   /*
    * loginUser fuction used to login.
    */


   loginUser2(value) {
 
 
 

      this.http.get<any>(this.algel.baseURL ,  this.algel.setHeaders(value,43))
      .subscribe(
        (response) => {                           //Next callback
          console.log('response received')
          console.log(response);
      this.girisYap2(response);
      //  if(sorgu=="1")this.auth.girisYap(response);
        },
        (error) => {                              //Error callback
          console.error('Request failed with error')
        alert(JSON.stringify(error));
        },
        () => {                                   //Complete callback
        //  console.log('Request completed')
        })
  
  
      /*
      this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(value.email,value.password)
      .then(value => {
         this.setLocalUserProfile(value);
         this.toastr.success('You have been successfully logged In!');
         this.router.navigate(['/']);
      })
      .catch(err => {
         this.toastr.error(err.message);
      });*/
   }
   loginUser(value) {
 
 
 

      this.http.get<any>(this.algel.baseURL ,  this.algel.setHeaders(value,1))
      .subscribe(
        (response) => {                           //Next callback
          console.log('response received')
          console.log(response);
      this.girisYap(response);
      //  if(sorgu=="1")this.auth.girisYap(response);
        },
        (error) => {                              //Error callback
          console.error('Request failed with error')
        alert(JSON.stringify(error));
        },
        () => {                                   //Complete callback
        //  console.log('Request completed')
        })
  
  
      /*
      this.firebaseAuth
      .auth
      .signInWithEmailAndPassword(value.email,value.password)
      .then(value => {
         this.setLocalUserProfile(value);
         this.toastr.success('You have been successfully logged In!');
         this.router.navigate(['/']);
      })
      .catch(err => {
         this.toastr.error(err.message);
      });*/
   }
 
   /*
    * resetPassword is used to reset your password.
    */
   resetPassword(value) {

      /*
      this.firebaseAuth.auth.sendPasswordResetEmail(value.email)
         .then(value => {
          	this.toastr.success("Email Sent");
          	this.router.navigate(['/session/loginone']);
         })
         .catch(err => {
            this.toastr.error(err.message);
         });*/
    }

   /*
    * logOut function is used to sign out . 
    */
   logOut() {

      /*
      this.firebaseAuth
      .auth
      .signOut();*/
      localStorage.removeItem("uye");
      localStorage.removeItem("seralar");
      this.isLoggedIn = false;
      this.toastr.success("Başarıyla çıkış yaptınız.");
      this.router.navigate(['/session/loginone']);
   }   

   /*
    * setLocalUserProfile function is used to set local user profile data.
    */
   setLocalUserProfile(value,seralar){
      
      localStorage.setItem("uye", JSON.stringify(value));
       localStorage.setItem("seralar", JSON.stringify(seralar));


      this.isLoggedIn = true;
   }
}
