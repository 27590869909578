import { Injectable } from '@angular/core';
 
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
 
import {Md5} from 'ts-md5/dist/md5'; 
import {Sonuc2,Sonuc} from '../sera/kapak';
 


@Injectable({
  providedIn: 'root'
})
export class KapaklarService {
  baseURL="https://php.otomatiksera.com/php/api.php"
  md5 = new Md5();
  public setHeaders(params,sorgu) {      
    const accessToken = "11111111111111111111111111";
    const reqData = {
      
    };
    if(params) {
        let reqParams = {};        
        Object.keys(params).map(k =>{

          if(k=="islem")params[k]=JSON.stringify(params[k]);
          if(k=="sifre" && s=="1"){
            var t=this.md5 .start().appendStr(params.kadi).end();
   

            var sifre= this.md5.start().appendStr(t+'.'+params.sifre).end() ;
            
            params[k]=sifre;
          }
            reqParams[k] = params[k];
        });
        var s="s";
        reqParams[s] = sorgu;
        reqData['params'] = reqParams;
    }

 
    return reqData;
}

    constructor(  private http: HttpClient) {
 
}

getUye(){
  return   JSON.parse(localStorage.getItem("uye"));
}


getBakiyee(): Observable<Sonuc>{
  var t=this.getUye();
  return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"61")).pipe(
    tap(data =>{
   //  console.log('All: '+cihaz+"  " +JSON.stringify(data)  )
    } ),
    catchError(this.handleError)
);
}



getGunluk(cihaz,ta): Observable<Sonuc> {
  var t=this.getUye();
t.cihaz=cihaz;
t.z=ta;
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"14")).pipe(
     tap(data =>{
    //  console.log('All: '+cihaz+"  " +JSON.stringify(data)  )
     } ),
     catchError(this.handleError)
 );
}



getGuncel(): Observable<Sonuc> {
  var t=this.getUye();
 
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"43")).pipe(
     tap(data =>{
    
     } ),
     catchError(this.handleError)
 );
}
uyeBakiye(bakiye){
  localStorage.setItem("bakiye", bakiye);

}


getKapaklar(sera): Observable<Sonuc> {
   var t=this.getUye();
 t.s_id=sera;
  return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"2")).pipe(
      tap(data =>{
        //console.log('All: ' +JSON.stringify(data)  )
      } ),
      catchError(this.handleError)
  );
}
 
yapilacakGonder(deger,ser): Observable<Sonuc2> {
  var t=this.getUye();
 t.islem=deger;
 console.log('All: ' +JSON.stringify(deger)  )
 return this.http.get<Sonuc2>(this.baseURL,this.setHeaders(t,ser)).pipe(
     tap(data =>{
      

     } ),
     catchError(this.handleError)
 );
}

getBakiye(): Observable<Sonuc> {
  var t=this.getUye();
 
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"60")).pipe(
     tap(data =>{
       console.log('All: ' +JSON.stringify(data)  ) 
     } ),
     catchError(this.handleError)
 );
}
getYapilacaklar(): Observable<Sonuc> {
  var t=this.getUye();
 
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"56")).pipe(
     tap(data =>{
       //console.log('All: ' +JSON.stringify(data)  ) 
     } ),
     catchError(this.handleError)
 );
}
getKapaklarListe(liste): Observable<Sonuc> {
  var t=this.getUye();
t.l_id=liste;
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"7")).pipe(
     tap(data =>{
     
     } ),
     catchError(this.handleError)
 );
}
getNumaralarListe(sera): Observable<Sonuc> {
  var t=this.getUye();
t.s_id=sera;
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"32")).pipe(
     tap(data =>{
     
     } ),
     catchError(this.handleError)
 );
}   
getSeralarListe(): Observable<Sonuc> {
  var t=this.getUye();

 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"31")).pipe(
     tap(data =>{
     
     } ),
     catchError(this.handleError)
 );
}
getVanalarListe(liste): Observable<Sonuc> {
  var t=this.getUye();
t.l_id=liste;
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"22")).pipe(
     tap(data =>{
     
     } ),
     catchError(this.handleError)
 );
}

getListeler(): Observable<Sonuc> {
  var t=this.getUye();
 
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"6")).pipe(
     tap(data =>{
  
     } ),
     catchError(this.handleError)
 );
}

getZincirler(): Observable<Sonuc> {
  var t=this.getUye();
 
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"18")).pipe(
     tap(data =>{
  
     } ),
     catchError(this.handleError)
 );
}

 

getOtomatik(ele): Observable<Sonuc> {
  var t=this.getUye();
 t.oto=ele;

 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"15")).pipe(
     tap(data =>{
      
     } ),
     catchError(this.handleError)
 );
}


getVana(ele): Observable<Sonuc> {
  var t=this.getUye();
 t.oto=ele;

 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,"15")).pipe(
     tap(data =>{
      
     } ),
     catchError(this.handleError)
 );
}
komutGonder(deger,ser): Observable<Sonuc2> {
  var t=this.getUye();
t.islem=deger;
 
 return this.http.get<Sonuc2>(this.baseURL,this.setHeaders(t,ser)).pipe(
     tap(data =>{
      
     // console.log('All: ' +JSON.stringify(data)  )
     } ),
     catchError(this.handleError)
 );
}

getIsiNem(): Observable<Sonuc> {
  var t=this.getUye();
 
 
 return this.http.get<Sonuc>(this.baseURL,this.setHeaders(t,13)).pipe(
     tap(data =>{
      
      // console.log('All: ' +JSON.stringify(data)  )
     } ),
     catchError(this.handleError)
 );
}




private handleError(err: HttpErrorResponse) {
 
  let errorMessage = '';
  if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
  } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
  }
  console.error(errorMessage);
  return throwError(errorMessage);
}
}